// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { Form, Button, Slider, text } from '@toggl/ui'
import { useFormik } from 'formik'
import { media, colors } from '@toggl/style'

const validate = (values) => {
  const errors = {}

  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  if (!values.phone) {
    errors.phone = 'Required'
  } else if (values.phone.length < 8) {
    errors.phone = 'Must be 28 characters or more'
  }

  return errors
}

const values = [
  '1 - 14',
  '15 - 25',
  '26 - 50',
  '51 - 100',
  '101 - 150',
  '151 - 250',
  '250+',
]

type Props = {
  title: React.Node,
  subtitle: React.Node,
}

export default function ReqyestDemo({ title, subtitle }: Props) {
  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
      teamSize: '',
    },
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2))
    },
  })
  return (
    <FormWrapper>
      {title}
      {subtitle}
      <StyledForm onSubmit={formik.handleSubmit}>
        <Form.Input
          id="email"
          label={<label htmlFor="firstName">Email</label>}
          name="email"
          type="email"
          placeholder={'Work Email'}
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.errors.email ? <div>{formik.errors.email}</div> : null}
        />

        <Form.Input
          id="phone"
          label={<label htmlFor="phone">Password</label>}
          name="phone"
          type="phone"
          placeholder={'Phone Number'}
          onChange={formik.handleChange}
          value={formik.values.phone}
          error={formik.errors.phone ? <div>{formik.errors.phone}</div> : null}
        />
        <Slider
          label={<label htmlFor="teamSize">Team size</label>}
          error={
            formik.errors.teamSize ? <div>{formik.errors.teamSize}</div> : null
          }
          values={values}
          onChange={(value) => formik.setFieldValue('teamSize', value)}
        />
        <Button.Primary type="submit">Submit</Button.Primary>
      </StyledForm>
    </FormWrapper>
  )
}

const StyledForm = styled(Form.Wrapper)`
  max-width: 500px;
  margin-top: 40px;
`

const FormWrapper: React.ComponentType<any> = styled.div`
  p {
    max-width: 500px;
  }
  svg {
    width: 30px;
  }
  h1 + p,
  h2 + p,
  h3 + p,
  h4 + p,
  h5 + p {
    margin-top: 20px;
  }
  text-align: center;

  ${media.mq[1]} {
    text-align: inherit;
    padding-right: 70px;

    h4 {
      ${text.heading3};
      color: ${colors.darkPurple};
    }
  }
`
