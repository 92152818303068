// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { Section, text, hooks, Button, Brands, Card, Icon } from '@toggl/ui'
import { media, colors, themes } from '@toggl/style'
import IndexLayout from 'layouts'
import LayoutTrack from 'components/LayoutTrack'
import type { Persona } from 'types'
import type { PageProps } from 'gatsby'
import MDX from 'components/mdx'
import { Video } from 'components/video'
import { Image } from 'components/image'
import brandsImg from 'images/uploads/brands-desktop.png'
import brandsImgMobile from 'images/uploads/brands-mobile.png'
import article1 from 'images/uploads/article1.png'
import { Link } from 'gatsby'
import { ThemeProvider } from 'emotion-theming'
import RequestDemo from 'components/RequestDemo'

const HeroComponent = ({ children }) => {
  return <>{children}</>
}

const PersonaPage = ({
  pageContext: { data },
}: PageProps<null, { data: Persona }>) => {
  const isDesktop = hooks.useMediaQuery(media.mqbp[1])

  return (
    <IndexLayout
      title={data.seo?.title}
      description={data.seo?.description}
      ogImage={data.seo?.ogImage}
    >
      <LayoutTrack Hero={HeroComponent}>
        <Section.Block flex={isDesktop} background={colors.darkPurple}>
          <RequestDemo
            title={<MDX>{data.sections.form.heading}</MDX>}
            subtitle={<MDX p={text.P3}>{data.sections.form.description}</MDX>}
          />

          <Section.ModalVideo
            skewed
            placeholder={
              data.sections.form.placeholder && (
                <Image
                  src={data.sections.form.placeholder}
                  alt={'request demo'}
                />
              )
            }
          >
            <Video src={data.sections.form.video} />
          </Section.ModalVideo>
        </Section.Block>
        <Section.Block flex center background={colors.almostWhite}>
          <Brands
            title="Reliable time-tracking loved by 5M+ users"
            subtitle="And trusted by companies worldwide to track their week and create reports that clients love."
            imgSrc={brandsImg}
            mobileImgSrc={brandsImgMobile}
          />
        </Section.Block>
        <Section.Skewed
          color={colors.beige}
          backgroundColor={colors.almostWhite}
        >
          <PointsWrapper>
            <h2>
              Leverage Toggl Track data to <em>boost</em> your business
            </h2>
            <PointsGrid>
              <Point>
                <Icon.Calendar />
                Accurately scope projects & pinpoint scope creep
              </Point>
              <Point>
                <Icon.PiggyBank />
                Plan project budgets with confidence
              </Point>
              <Point>
                <Icon.Process />
                Improve staffing & team allocation
              </Point>
              <Point>
                <Icon.Magnifier />
                Ensure profitability by comparing labor costs and billable rates
              </Point>
              <Point>
                <Icon.Budget />
                Quote new projects with precision based on hard data
              </Point>
              <Point>
                <Icon.Hand />
                Cultivate client trust by including time reports on invoices
              </Point>
            </PointsGrid>
          </PointsWrapper>
        </Section.Skewed>
        <StyledSection>
          <Card.Large>
            <CaseStudyContent>
              <Left>
                <MDX>{data.sections.testimony.quote}</MDX>
                <MDX p={text.P2}>{data.sections.testimony.description}</MDX>
                <Link to={data.sections.testimony.cta.href}>
                  <Button.Secondary color={colors.beige}>
                    {data.sections.testimony.cta.text}
                  </Button.Secondary>
                </Link>
              </Left>
              <Right>
                <ThemeProvider theme={themes.track}>
                  <Section.ModalVideo placeholder={article1} skewed={false}>
                    <iframe
                      src={'https://www.youtube.com/watch?v=vU3QC0NNoDo'}
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                    />
                  </Section.ModalVideo>

                  <CaseStudyContentRightH3>
                    Curious how Toggl Track can help your business?
                  </CaseStudyContentRightH3>
                  <Link to={'/track/signup'}>
                    <Button.Primary>Try now</Button.Primary>
                  </Link>
                </ThemeProvider>
              </Right>
            </CaseStudyContent>
          </Card.Large>
        </StyledSection>
        <Section.Skewed
          color={colors.darkPurple}
          backgroundColor={isDesktop ? colors.fadedPurple : colors.beige}
          left={'-210px'}
        >
          <Card.FeaturesWrapper>
            <MDX>{data.sections.features.heading}</MDX>
            <Card.Features>
              {data.sections.features.items.map((feature, index) => {
                return (
                  <Card.Feature
                    key={index}
                    title={feature.title}
                    paragraph={feature.description}
                    image={<Image src={feature.image} alt={feature.title} />}
                  />
                )
              })}
            </Card.Features>
          </Card.FeaturesWrapper>
        </Section.Skewed>
        <Section.Block flex center background={colors.darkPurple}>
          <MDX>{data.sections.features.heading}</MDX>
        </Section.Block>

        <Section.Block flex center background={colors.darkPurple}>
          <Features2>
            {data.sections.features.items2.map((feature, index) => {
              return (
                <Feature key={index}>
                  <Ball />
                  <FeatureText>
                    <MDX>{feature.title}</MDX>
                    <MDX p={text.P3}>{feature.description}</MDX>
                  </FeatureText>
                </Feature>
              )
            })}
          </Features2>
        </Section.Block>
        <Section.Block center flex background={colors.darkPurple}>
          <Cta>
            <MDX>{data.sections.cta.heading}</MDX>
            <CtaButtons>
              <Button.Primary
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                }}
              >
                {data.sections.cta.cta.text}
              </Button.Primary>
              <Link to={data.sections.cta.cta.href}>
                <Button.Secondary color={colors.beige}>
                  {data.sections.cta.cta.text}
                </Button.Secondary>
              </Link>
            </CtaButtons>
          </Cta>
        </Section.Block>
      </LayoutTrack>
    </IndexLayout>
  )
}

const Features2 = styled.div`
  padding-top: 25px;
  max-width: 700px;
  margin: 0 auto;
`
const Feature = styled.div`
  display: flex;
  margin-bottom: 25px;
`
const Ball = styled.div`
  background: ${colors.beige};
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
`
const FeatureText = styled.div`
  display: flex;
  flex-direction: column;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${(p) => p.theme.primary};
    text-align: left;
  }

  p {
    text-align: left;
    margin-top: 15px;
  }
`
const CtaButtons = styled.div`
  button {
    margin: 0 auto;
  }

  a {
    margin-top: 25px;
    display: block;
  }
`
const Cta = styled.div`
  display: flex;
  flex-direction: column;

  ${CtaButtons} {
    margin-top: 25px;
  }

  ${media.mq[1]} {
    margin: 0 auto;
  }
`

const CaseStudyContentRightH3 = styled.h3`
  ${text.heading4};
  color: ${colors.darkPurple};
  width: 284px;
  margin: 27px 0;

  ${media.mq[1]} {
    margin-top: 63px;
    margin-bottom: 25px;
  }
`

const StyledSection = styled.div`
  ${media.mq[1]} {
    background-color: ${colors.fadedPurple};
    display: flex;
    padding-top: 30px;
  }
`

const CaseStudyContent = styled.div`
  background-color: ${colors.darkPurple};

  ${media.mq[1]} {
    display: flex;
  }
`

const Left = styled.div`
  padding: 80px 30px;

  h3 {
    ${text.heading4};
  }

  h3 + p {
    margin-top: 20px;
  }

  a {
    margin-top: 20px;
  }

  ${media.mq[1]} {
    width: 710px;
    padding: 90px 120px;
    display: flex;
    flex-direction: column;

    a {
      margin-top: 40px;
      display: block;
    }

    h3 {
      margin-bottom: 140px;
    }
  }
`

const Right = styled.div`
  padding-bottom: 150px;
  background-color: ${colors.beige};
  display: flex;
  flex-direction: column;

  ${media.mq[1]} {
    padding: 30px;
    padding-bottom: 90px;
  }
`

const Point = styled.div`
  display: flex;
  align-items: center;
  ${text.paragraph3};
  color: ${colors.darkPurple};

  ${media.mq[1]} {
    svg {
      margin-right: 45px;
    }
  }
`

const PointsWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    color: ${colors.darkPurple};
  }

  h2 {
    text-align: center;
  }

  svg {
    width: 30px;
  }

  ${media.mq[1]} {
    padding-bottom: 80px;
  }
`

const PointsGrid = styled.div`
  margin-top: 25px;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
  column-gap: 50px;
  row-gap: 20px;
  display: grid;

  svg {
    margin-right: 15px;
  }

  ${media.mq[1]} {
    margin-top: 100px;
    grid-template-columns: repeat(2, 2fr);
    grid-template-rows: 1fr 1fr;
    column-gap: 50px;
    row-gap: 20px;
  }
`

export default PersonaPage
